<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-select-compare"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Consolidador de Quantidades
            </div>
          </template>
          <v-btn
            v-if="accessReleased('CONSOLIDADOR_QUANTIDADES_ADICIONAR')"
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('add')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Novo Relatório
          </v-btn>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-relatorios"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />
          <general-progress-bars
            class="mt-11"
            :items="downloadProgressData"
            prefixText="Baixando logs ..."
          />
          <v-divider class="mt-10" />
          <v-data-table
            class="bs-relatorios-table"
            :headers="[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Título',
                value: 'titulo'
              },
              {
                text: 'TUCs',
                value: 'tucs_selecionadas'
              },
              {
                text: 'Base A',
                value: 'base_a_txt'
              },
              {
                text: 'Base B',
                value: 'base_b_txt'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="relatorios"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            :mobile-breakpoint="800"
            no-data-text="Nenhum relatório encontrado"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | addLeadingZero }}
            </template>
            <template v-slot:[`item.tucs_selecionadas`]="{ item }">
              <div
                class="clickable-item"
                @click="openDialogTucsSelecionadas(item.tucs_selecionadas)"
              >
                {{ item.tucs_selecionadas | exibeResumoTucsSelecionadas }}
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <general-status :status="item.status" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                :class="item.status != 'SUCESSO' ? 'cursor-block' : ''"
                :disabled="item.status != 'SUCESSO'"
                color="gray"
                min-width="0"
                class="px-1"
                fab
                icon
                x-small
                :loading="
                  downloadProgressData[item.id] !== undefined ? true : false
                "
                @click="baixarResultado(item)"
              >
                <v-icon small> mdi-download </v-icon>
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="white"
                    class="ml-1"
                    height="22px"
                    width="22px"
                    fab
                    x-small
                    elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="podeExecutarRelatorio(item.status)"
                    @click="executarRelatorio(item)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-play </v-icon>
                      Executar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="podeDeletarRelatorio(item)"
                    @click="openDialogDeletarRelatorio(item)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-trash-can-outline </v-icon>
                      Deletar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="
                      (dialogCreationLog = true),
                        (dialogCreationLogData = {
                          username: item.usuario,
                          created: item.created
                        })
                    "
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-table-search </v-icon>
                      Log
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getRelatorios"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Clique aqui para recarregar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </base-material-card>
        <v-dialog
          v-model="dialogTucsSelecionadas"
          scrollable
          max-width="500px"
        >
          <v-card>
            <v-card-title
              class="py-3"
              style="text-align: left !important"
            >
              <h2>TUCs selecionadas</h2>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-3">
              <v-textarea
                class="ta-padding-c"
                rows="10"
                no-resize
                background-color="#EEEEEE"
                :readonly="true"
                :value="dialogTucsSelecionadasData.join('\n')"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions
              style="text-align: right !important; display: block !important"
            >
              <v-btn
                color="green darken-1"
                @click="dialogTucsSelecionadas = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <dialog-creation-log
          :dialog.sync="dialogCreationLog"
          :item="dialogCreationLogData"
        />
        <dialog-delete
          :dialog.sync="dialogDeletarRelatorio"
          @submitTriggered="deletarRelatorio"
        >
          <template slot="title">
            Deletar Relatório #{{ dialogDeletarRelatorioData.id }}
          </template>
          <template slot="body">
            Tem certeza que deseja deletar o relatório
            {{ dialogDeletarRelatorioData.titulo }}?
          </template>
        </dialog-delete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import AuthService from '@/services/AuthService';
import ConsolidadorQuantidadesService from '@/services/ConsolidadorQuantidadesService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
import DialogCreationLog from '@/components/general/DialogCreationLog';
import DialogDelete from '@/components/general/DialogDelete';
import GeneralStatus from '@/components/general/GeneralStatus';
import GeneralProgressBars from '@/components/general/GeneralProgressBars';

export default {
  name: 'ConsolidadorQuantidadesHistorico',
  mixins: [refreshDataMixins, profilePermissionsMixin],
  components: {
    DialogCreationLog,
    DialogDelete,
    GeneralStatus,
    GeneralProgressBars
  },
  data: () => ({
    relatorios: [],
    search: '',
    loading: false,
    dialogDeletarRelatorioData: {},
    dialogDeletarRelatorio: false,
    dialogCreationLog: false,
    dialogCreationLogData: {},
    dialogTucsSelecionadas: false,
    dialogTucsSelecionadasData: [],
    downloadProgressData: {},
    usuarioId: null,
    userIsAdmin: false
  }),
  mounted() {
    this.getUserInfo();
    this.refreshData(this.getRelatorios);
  },
  methods: {
    getRelatorios() {
      this.loading = true;
      ConsolidadorQuantidadesService.getRelatorios()
        .then((relatorios) => (this.relatorios = relatorios))
        .catch((err) => {
          console.error('Erro: ', err);
          this.$toast.error('Erro ao buscar os relatórios.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },
    async getUserInfo() {
      this.usuarioId = this.$store.getters.getUserId;
      this.userIsAdmin = await AuthService.userIsAdmin();
    },
    openDialogTucsSelecionadas(tucsSelecionadas) {
      this.dialogTucsSelecionadasData = tucsSelecionadas;
      this.dialogTucsSelecionadas = true;
    },
    openDialogDeletarRelatorio(relatorio) {
      this.dialogTucsSelecionadasData = relatorio;
      this.dialogTucsSelecionadas = true;
    },
    openDialogDeletarRelatorio(relatorio) {
      this.dialogDeletarRelatorioData = {
        id: relatorio.id,
        titulo: relatorio.titulo
      };
      this.dialogDeletarRelatorio = true;
    },
    podeDeletarRelatorio(item) {
      return (
        item.status !== 'EXECUTANDO' &&
        (this.userIsAdmin || this.usuarioId === item.usuario_id)
      );
    },
    podeExecutarRelatorio(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    podeBaixarRelatorio(status) {
      return status === 'SUCESSO';
    },
    deletarRelatorio() {
      ConsolidadorQuantidadesService.deletarRelatorio(
        this.dialogDeletarRelatorioData.id
      )
        .then(() => {
          this.$toast.success('Relatório removido com sucesso.', '', {
            position: 'topRight'
          });
          this.getRelatorios();
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar o relatório.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogDeletarRelatorio = false;
    },
    executarRelatorio(relatorio) {
      ConsolidadorQuantidadesService.executarRelatorio(relatorio.id)
        .then(() => {
          this.$toast.info(
            'O processamento ocorrerá em instantes. Por favor, aguarde.',
            '',
            { position: 'topRight', timeout: 2000 }
          );
          this.getRelatorios();
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao agendar a execução do relatório ${relatorio.titulo}.`,
            '',
            {
              position: 'topRight'
            }
          );
        });
    },
    baixarResultado(relatorio) {
      const { id } = relatorio;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeArquivo = `relatorio-consolidador-quantidades-${id}-${timestamp}.xlsx`;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      ConsolidadorQuantidadesService.baixarResultado(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo resultado do relatório.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  },
  filters: {
    exibeResumoTucsSelecionadas(arrayTucs) {
      let formattedValue = String(arrayTucs[0]).toUpperCase();
      let lengthArray = arrayTucs.length;
      formattedValue =
        lengthArray > 1
          ? `${formattedValue} + ${lengthArray - 1}`
          : formattedValue;
      return formattedValue;
    }
  }
};
</script>
