var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-select-compare","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Consolidador de Quantidades ")])]},proxy:true}])},[(_vm.accessReleased('CONSOLIDADOR_QUANTIDADES_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo Relatório ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-relatorios",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('general-progress-bars',{staticClass:"mt-11",attrs:{"items":_vm.downloadProgressData,"prefixText":"Baixando logs ..."}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"bs-relatorios-table",attrs:{"headers":[
            {
              text: '#',
              value: 'id'
            },
            {
              text: 'Título',
              value: 'titulo'
            },
            {
              text: 'TUCs',
              value: 'tucs_selecionadas'
            },
            {
              text: 'Base A',
              value: 'base_a_txt'
            },
            {
              text: 'Base B',
              value: 'base_b_txt'
            },
            {
              text: 'Status',
              value: 'status'
            },
            {
              sortable: false,
              text: 'Ações',
              value: 'actions',
              class: 'pl-4'
            }
          ],"items":_vm.relatorios,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"mobile-breakpoint":800,"no-data-text":"Nenhum relatório encontrado","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.tucs_selecionadas",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"clickable-item",on:{"click":function($event){return _vm.openDialogTucsSelecionadas(item.tucs_selecionadas)}}},[_vm._v(" "+_vm._s(_vm._f("exibeResumoTucsSelecionadas")(item.tucs_selecionadas))+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:item.status != 'SUCESSO' ? 'cursor-block' : '',attrs:{"disabled":item.status != 'SUCESSO',"color":"gray","min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id] !== undefined ? true : false},on:{"click":function($event){return _vm.baixarResultado(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.podeExecutarRelatorio(item.status))?_c('v-list-item',{on:{"click":function($event){return _vm.executarRelatorio(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" Executar ")],1)],1):_vm._e(),(_vm.podeDeletarRelatorio(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDeletarRelatorio(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")]),_vm._v(" Deletar ")],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){(_vm.dialogCreationLog = true),
                      (_vm.dialogCreationLogData = {
                        username: item.usuario,
                        created: item.created
                      })}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.getRelatorios}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar")])])]},proxy:true}],null,true)})],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialogTucsSelecionadas),callback:function ($$v) {_vm.dialogTucsSelecionadas=$$v},expression:"dialogTucsSelecionadas"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-3",staticStyle:{"text-align":"left !important"}},[_c('h2',[_vm._v("TUCs selecionadas")])]),_c('v-divider'),_c('v-card-text',{staticClass:"py-3"},[_c('v-textarea',{staticClass:"ta-padding-c",attrs:{"rows":"10","no-resize":"","background-color":"#EEEEEE","readonly":true,"value":_vm.dialogTucsSelecionadasData.join('\n')}})],1),_c('v-divider'),_c('v-card-actions',{staticStyle:{"text-align":"right !important","display":"block !important"}},[_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.dialogTucsSelecionadas = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDeletarRelatorio},on:{"update:dialog":function($event){_vm.dialogDeletarRelatorio=$event},"submitTriggered":_vm.deletarRelatorio}},[_c('template',{slot:"title"},[_vm._v(" Deletar Relatório #"+_vm._s(_vm.dialogDeletarRelatorioData.id)+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar o relatório "+_vm._s(_vm.dialogDeletarRelatorioData.titulo)+"? ")])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }